<template lang="pug">
div
  quote-info.bottom-50(ref='quote' :value='origin' :no_edit="!(quoteState == 'unoffer' || quoteState == 'offering')")
  loading-view(:value="loadingState")
</template>

<script>
import formmixin from '../../components/form'
export default {
  mixins: [formmixin],
  data() {
    return {
      loadingState: false,
      commitState: false,
      id: 0,
      showlog: false,
      quote_state: '', // 订单状态 查看offer.vue 中的quote_state
      origin: null,
    }
  },
  computed: {
    quoteState() {
      return this.quote_state
    }
  },
  methods: {
    async getQuote() {
      let rel = {
        relation: [
          { 
            table: 'quote_item', 
            key: 'quote_poi_quote', 
            order: 'order',
            needcount: 0 
          }
        ]
      }
      let params = {
        id: this.id,
        with: {
          relation: [
            {
              table: "quote_item",
              key: "quote_poi_quote", 
              needcount: 0,
              order: "order"
            }
          ],
        }
      }
      let res = (await this.$api.GetQuoteNew(params)).data
      let { quote_state, id } = res
      this.quote_state = quote_state
      this.id = id
      this.origin = res
    }
  },
  async mounted() {
    this.id = this.$route.query.id
    // console.log(this.id)
    this.loadingState = true
    await this.getQuote()
    this.loadingState = false
    this.$emit('on-nav', {
      leftText: '返回',
      leftArrow: true,
      rightText: '主页',
      rightArrow: true,
      onClickLeft: () => {
        this.$router.go(-1)
      },
      onClickRight: () => {
        this.$router.push({name: 'home'})
      }
    })
  }
};
</script>
